import LoginManager from './LoginManager';

const script = document.currentScript;
const INACTIVITY_TIMEOUT = 5 * 60 * 1000; // 5 minutes in milliseconds

if (script?.hasAttribute('util')) {
} else if (script?.hasAttribute('allowUnauthorized') || script?.hasAttribute('allowunauthorized')) {
  if ((LoginManager.hasCookie('refreshToken') && !LoginManager.hasCookie('token')) || (LoginManager.hasCookie('refreshToken') && LoginManager.tokenExpired(LoginManager.parseJwt(LoginManager.getCookie('token')!)))) if (!LoginManager.isUpdating) LoginManager.refreshToken();
} else if (script?.hasAttribute('alwaysActive') || script?.hasAttribute('alwaysactive')) {
  let isActive = true;
  let inactivityTimer: number | null = null;

  document.addEventListener('visibilitychange', () => {
    isActive = !document.hidden;
  });

  const resetInactivityTimer = () => {
    if (!isActive)
      LoginManager.validateToken();

    isActive = true;

    if (inactivityTimer)
      window.clearTimeout(inactivityTimer);

    inactivityTimer = window.setTimeout(() => {
      isActive = false;
    }, INACTIVITY_TIMEOUT);
  };

  // Set up event listeners for user activity
  ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'].forEach((eventName) => {
    document.addEventListener(eventName, resetInactivityTimer, true);
  });

  // Initialize the inactivity timer
  resetInactivityTimer();

  (async () => {
    while (true) {
      if (LoginManager.isUpdating) {
        await LoginManager.sleep(500);
        continue;
      }

      // Only validate token if user is active
      if (isActive) {
        if (LoginManager.hasCookie('token')) {
          const token = LoginManager.parseJwt(LoginManager.getCookie('token')!);

          await LoginManager.sleep(new Date(token.exp * 1000).getTime() - 60000 - new Date().getTime());
        } else {
          await LoginManager.sleep(1000);
        }

        LoginManager.validateToken();
      } else {
        await LoginManager.sleep(5000);
      }
    }
  })
} else {
  if (LoginManager.hasCookie('refreshToken')) {
    LoginManager.validateToken();
  } else {
    window.location.href = LoginManager.buildLoginUrl(window.location.href);
  }
}
